.card h1 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.card p {
    font-size: 1rem;
    align-items: left;
}

.card img {
    width: 6.25rem;
    height: 6.1rem;
    margin-left: 3.6rem;
    margin-bottom: 3rem;
}

.card {
    width: 17.5rem;
    height: 30rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
}

.card div {
    height: 100%;
}

@media (max-width: 415px) {
    .card h1 {
        font-size: 0.75rem;
    }
    
    .card p {
        font-size: 0.6rem;
    }

    .card img {
        width: 3.08rem;
        height: 3.03rem;
        margin-bottom: 0rem;
        margin-left: 5rem;
    }

    .card {
        height: 15rem;
    }
}
