* {
    font-family: "Inter", "sans-serif";
}


.section-1, .section-2, .section-3, .section-4 {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 1.1rem;
}

.section-1 h1, .section-2 h1, .skillset, .section-4 h1 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.section-2 img {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30rem;

}

.section-1 p {
    font-size: 1.1rem;
    
}

.utility-1, .utility-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 5rem;
}

.clients {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.section-4 {
    margin-bottom: 5rem;
    margin-top: 0rem;
}

.section-2 h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.section-2 h3 {
    font-size: 1.1rem;
}

@media (max-width: 415px) {
    .section-1 h1, .section-2 h1, .skillset, .section-4 h1 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .section-1 p {
        font-size: 0.75rem;
    }

    .section-1, .section-2, .section-3, .section-4 {
        margin-left: 3rem;
        margin-right: 2rem;
        margin-top: 1rem;
    }

    .section-2 h2 {
        font-size: 0.70rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .section-2 h3 {
        font-size: 0.6rem;
    }
    
    .utility-1, .utility-2 {
        padding-bottom: 1rem;
    }

    .section-4 {
        padding-bottom: 0rem;
        margin-top: 2rem;
    }

}
