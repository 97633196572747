.card {
    width: 36.06rem;
    height: 15rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.85rem;
}

.image-container {
    width: 40%
}

.card p {
    padding-bottom:1rem;
}

.content {
    margin-left: 2rem;
    margin-top: 2rem;
    display: flex;
    height: 80%;
    width: 80%
}

.text-container {
    margin-left: 10%;
}



@media (max-width: 414px) {
    .card {
        width: 17.5rem;
        height: 15rem; 
        font-size: 0.5rem;
        margin-bottom: 1rem;
    }

    .content {
        padding: 0.5rem;
        display: block;
    }
    
    .card img {
        width: 40%;
        height:40%;
        margin-left: 30%;
    }
}

