* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.header-comp {
    background-color: black;
    display: flex;
    align-items: center;
}

.title {
    color: white;
    font-family: "Inter", "sans-serif";
    font-weight: bold;
    font-size: 1.75rem;
    padding-left: 1rem;
    
}

.img-comp img {
    height: 3rem;
    width: 2rem;
    margin-left: 5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media (max-width: 415px) {
    .img-comp img {
        width: 2rem;
        height: 3rem;
        margin-left: 2rem;
    }

    .title {
        font-size: 2rem;
        padding-left: 0.5rem;
    }
}