* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.foot-comp {
    background-color: black;
    padding-left: 5rem;
}

.foot-comp h1, .foot-comp p {
    color: white;
}

.foot-section {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}
.foot-section img, .foot-section h1, .foot-section p {
    padding-right: 0.7rem;
}

footer img {
    width: 3.8rem;
}

.foot-up {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
}

.some-text {
    margin-right: 5rem
}

@media (max-width: 415px) {

    .foot-comp {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .foot-up p {
        font-size: 0.5rem;
    }
    
    .some-text {
        margin-left: 4.1rem;
        margin-right: 0rem;
    }
}